<template>
  <svg-icon
    :data="require('@icon/feather/chevron-right.svg')"
    outlined
    v-bind="$attrs"
  ></svg-icon>
</template>
<script lang="ts">
import { Vue, Component } from "vue-property-decorator";

@Component
export default class IconAngleRight extends Vue {}
</script>
